import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/layout";
import SubPage from "./comps/SubPage";
  
const Corte = () => {
  return (
    <Layout infoPage={{page: "tecnologia", sub: "corte"}}>
      <SubPage name="Corte" />
    </Layout>
  )
}

export default Corte;